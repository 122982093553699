<app-banner type="info">
    <h3 class='mat-headline'>Autoadmin has been updated</h3>
    <p class="mat-body-1">
        We updated Autoadmin application and improved the authorization process.
    </p>
    <p class="mat-body-1">
        That's why we kindly ask you to register and add a connection to your database again.
    </p>
    <p class="mat-body-1">
        We apologize for the inconvenience. That was necessary to make our service better.
    </p>
    <div class="error-actions">
        <a mat-stroked-button routerLink="/registration">Create account</a>
    </div>
</app-banner>

<mat-form-field class="example-form-field" appearance="outline">
    <mat-label>{{normalizedLabel}}</mat-label>
    <input matInput type="password" name="{{label}}-{{key}}" [required]="required" [disabled]="readonly"
        [disabled]="clearPassword"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
    <mat-hint>To keep password the same please keep this field blank.</mat-hint>
</mat-form-field>
<mat-checkbox class="password-checkbox"
    [disabled]="value !== '' && value !== null "
    [(ngModel)]="clearPassword"
    (ngModelChange)="onClearPasswordChange()">
    Clear password
</mat-checkbox>

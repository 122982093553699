<div class="contentLoader">
    <div class="customLoader">
        <div class="cord">
            <div class="ball"></div>
        </div>
        <div class="cord">
            <div class="ball"></div>
        </div>
        <div class="cord">
            <div class="ball"></div>
        </div>
        <div class="cord">
            <div class="ball"></div>
        </div>
        <div class="cord">
            <div class="ball"></div>
        </div>
    </div>
</div>

<mat-form-field class="select-form-field" appearance="outline">
    <mat-label>{{normalizedLabel}}</mat-label>
    <mat-select *ngIf="widgetStructure; else nativeSelect"
        name="{{label}}-{{key}}" [required]="required" [disabled]="readonly"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
        <mat-option *ngFor="let option of options"
            [value]="option.value">
            {{option.label}}
        </mat-option>
    </mat-select>
    <ng-template #nativeSelect>
        <mat-select name="{{label}}-{{key}}" [required]="required" [disabled]="readonly"
            [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
            <mat-option *ngFor="let option of options" [value]="option">
                {{option}}
            </mat-option>
        </mat-select>
    </ng-template>

</mat-form-field>

<div class="db-table-header" #focus>
    <div class="db-table-title">
        <h2 class="mat-h2">{{ displayName }}</h2>

        <button mat-icon-button (click)="loadRowsPage()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <div *ngIf="selection.selected.length; else generalActions" class="db-table-bulk-actions">
        <div class="actions">
            <ng-container *ngIf="tableData.tableBulkActions && tableData.tableBulkActions.length">
                <button type="button" mat-button *ngFor="let action of tableData.tableBulkActions"
                (click)="activateActions.emit({action, selectedRows: selection.selected})">
                    {{action.title}}
                </button>
            </ng-container>
            <button mat-button
                (click)="deleteRows.emit(selection.selected)">
                Delete
            </button>
        </div>
    </div>
    <ng-template #generalActions>
        <div class="db-table-actions">
            <mat-form-field appearance="standard" class="search-input">
                <input matInput name="search"
                    placeholder="Search"
                    [(ngModel)]="searchString"
                    (keyup.enter)="handleSearch()">
                <button mat-icon-button matSuffix *ngIf="searchString"
                    (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="actions">
                <a mat-button *ngIf="tableData && tableData.permissions && tableData.permissions.add && (!tableData.isEmptyTable || searchString || getFiltersCount(activeFilters))"
                    routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
                    angulartics2On="click"
                    angularticsAction="Tables: add row page is opened">
                    Add row
                </a>
                <a mat-button *ngIf="accessLevel === 'edit'"
                    routerLink="/dashboard/{{connectionID}}/{{name}}/settings"
                    angulartics2On="click"
                    angularticsAction="Tables: settings dialog is opened">
                    Settings
                </a>
                <a mat-button *ngIf="accessLevel === 'edit' && tableData"
                    routerLink="/dashboard/{{connectionID}}/{{name}}/widgets"
                    [ngClass]="{'action_active': tableData.widgetsCount}"
                    angulartics2On="click"
                    angularticsAction="Tables: widgets page is opened">
                    Widgets
                </a>
                <div [matTooltip]="!tableData.keyAttributes?.length ? 'Add primary keys to create custom actions for rows.' : null">
                    <a mat-button *ngIf="accessLevel === 'edit' && tableData" [ngClass]="{ 'action-button_disabled': !tableData.keyAttributes?.length }"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/actions"
                        [disabled]="!tableData.keyAttributes?.length"
                        angulartics2On="click"
                        angularticsAction="Tables: actions page is opened">
                        Actions
                        <mat-icon *ngIf="!tableData.keyAttributes?.length">
                            info_outline
                        </mat-icon>
                    </a>
                </div>

                <button mat-button [ngClass]="{'action_active': getFiltersCount(activeFilters)}"
                    angulartics2On="click"
                    angularticsAction="Tables: settings dialog is opened"
                    (click)="handleOpenFilters()">
                    Filter
                </button>
                <div *ngIf="tableData" class="db-table-manage-columns-button">
                    <button *ngIf="tableData.displayedColumns && tableData.columns" mat-button [matMenuTriggerFor]="menu">
                        Columns ({{ tableData.actionsColumnWidth === '0' ? tableData.displayedColumns.length : tableData.displayedDataColumns.length }}
                            /
                            {{ tableData.columns.length }})
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                            role="menuitemcheckbox"
                            *ngFor="let column of tableData.columns"
                            (click)="$event.stopPropagation();">
                            <mat-checkbox color="primary"
                                class="db-table-header__columnsCheckbox"
                                (change)="tableData.changleColumnList()"
                                [(ngModel)]="column.selected">
                                {{column.normalizedTitle}}
                            </mat-checkbox>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div *ngIf="getFiltersCount(activeFilters) !== 0" class="active-filters">
    <mat-chip-list
        cdkDropList
        cdkDropListOrientation="horizontal">
        <mat-chip
            class="example-box"
            cdkDrag
            *ngFor="let activeFilter of activeFilters | keyvalue"
            (removed)="removeFilter.emit(activeFilter.key)"
            >
            {{ getFilter(activeFilter.key, activeFilter.value) }}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
    <button mat-button color="warn" *ngIf="getFiltersCount(activeFilters) > 1" (click)="resetAllFilters.emit()">Reset filters</button>
</div>

<div class="mat-elevation-z4">

    <div class="spinner-wrapper" *ngIf="tableData && tableData.loading$ | async">
        <mat-spinner diameter="48"></mat-spinner>
    </div>

    <div class="table-box">
        <mat-table *ngIf="tableData" matSort [dataSource]="tableData" NgMatTableQueryReflector
            class="db-table"
            [ngClass]="tableData.actionsColumnWidth === '0' ? 'db-table_withoutActions' : 'db-table_withActions'"
            [style.--colCount]="tableData.displayedDataColumns?.length"
            [style.--lastColumnWidth]="tableData.actionsColumnWidth">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [disabled]="tableData.isEmptyTable"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <!--{{row | json}}-->
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="column" *ngFor="let column of tableData.displayedDataColumns">
                <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!isSortable(column)"> {{ tableData.dataNormalizedColumns[column] }} </mat-header-cell>
                <mat-cell *matCellDef="let element" data-hj-suppress>
                    <div class="table-cell-content">
                        <ng-container *ngIf="isForeignKey(column); else contentCell">
                            <a routerLink="/dashboard/{{connectionID}}/{{tableData.foreignKeys[column].referenced_table_name}}/entry"
                                class="foreign-key-link"
                                [queryParams]="getForeignKeyQueryParams(tableData.foreignKeys[column], element[column])">
                                {{ getCellValue(tableData.foreignKeys[column], element[column]) }}
                            </a>
                        </ng-container>
                        <ng-template #contentCell>
                            <ng-container *ngIf="isWidget(column); else simpleValue">
                                {{ getWidgetValue(column, element[column]) }}
                            </ng-container>
                            <ng-template #simpleValue>
                                <ng-container>
                                    {{element[column] || '—'}}
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions" [stickyEnd]="true">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>

                <mat-cell *matCellDef="let element">
                    <ng-container *ngIf="tableData.tableActions && tableData.tableActions.length">
                        <button type="button" mat-icon-button *ngFor="let action of tableData.tableActions"
                            [matTooltip]="action.title"
                            (click)="activateAction.emit({action, primaryKeys: tableData.getQueryParams(element), identityFieldValue: element[tableData.identityColumn]})">
                            <mat-icon fontSet="material-icons-outlined">
                                {{action.icon}}
                            </mat-icon>
                        </button>
                    </ng-container>
                    <a mat-icon-button *ngIf="tableData.permissions.edit"
                        routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
                        [queryParams]="tableData.getQueryParams(element)"
                        angulartics2On="click"
                        angularticsAction="Tables: edit row is opened"
                        matTooltip="Edit row">
                        <mat-icon>create</mat-icon>
                    </a>
                    <button type="button" mat-icon-button *ngIf="tableData.permissions.delete"
                        angulartics2On="click"
                        angularticsAction="Tables: delete row is opened"
                        matTooltip="Delete row"
                        (click)="activateAction.emit({action: {title: 'delete row', requireConfirmation: true}, primaryKeys: tableData.getQueryParams(element)})">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableData.displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: tableData.displayedColumns;"></mat-row>
        </mat-table>
    </div>

    <div *ngIf="tableData.isEmptyTable && (getFiltersCount(activeFilters) || searchString)" class="empty-table">
        <p class="mat-body-1">No field matches <span style="font-family: monospace;">"{{staticSearchString}}"</span>.</p>
        <p class="mat-body-1">If you want to find substring, please, use <strong>Filter</strong>.</p>
    </div>

    <div *ngIf="tableData.isEmptyTable && !(getFiltersCount(activeFilters) || searchString)" class="empty-table">
        <a mat-button color="accent"
            routerLink="/dashboard/{{connectionID}}/{{name}}/entry"
            angulartics2On="click"
            angularticsAction="Tables: add row page is opened">
            <mat-icon>add</mat-icon>
            Add row
        </a>
    </div>

    <mat-paginator
        [pageSize]="30"
        [pageSizeOptions]="[10, 30, 100, 300]"
        [showFirstLastButtons]="!tableData.largeDataset">
    </mat-paginator>
    <!--(page)="scrollUp()"}-->
</div>

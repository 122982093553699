<div class="wrapper">
    <div class="register-form-box">
        <app-alert></app-alert>
        <form class="register-form"
            #registrationForm="ngForm"
            (ngSubmit)="registerUser()">
            <h1 class="mat-h1">Create an account to manage your database</h1>

            <div id="google_registration_button"
                angulartics2On="click"
                angularticsAction="Reg: sign up with google">
            </div>

            <div class="divider"><span class="divider__label">or</span></div>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="email" #email="ngModel" required
                    angulartics2On="change"
                    angularticsAction="Reg: email is changed"
                    [(ngModel)]="user.email">
                <!-- <mat-error *ngIf="hostname.errors?.required && (hostname.invalid && hostname.touched)">Hostname should not be empty.</mat-error> -->
            </mat-form-field>

            <app-user-password label="Password" [value]="user.password" (onFieldChange)="updatePasswordField($event)"></app-user-password>

            <button type="submit" mat-raised-button color="accent" class="submit-button"
                angulartics2On="click"
                angularticsAction="Reg: sign up is clicked"
                [disabled]="submitting || registrationForm.form.invalid || registrationForm.form.pristine">
                {{ submitting ? 'Submitting' : 'Create account'}}
            </button>

            <div class="divider"></div>

            <div class="fb-login-button"
                data-width="400"
                data-size="large"
                data-button-type="continue_with"
                data-layout="default"
                data-auto-logout-link="false"
                data-use-continue-as="false"
                onlogin="loginWithFacebook()"
                angulartics2On="click"
                angularticsAction="Reg: sign up with fb">
            </div>
        </form>

        <p class="mat-caption agreement">
            By creating this account you agree to Autoadmin's <br/>
            <a href="https://autoadmin.org/privacy" class="link" target="_blanck">Privacy Policy</a>
            and
            <a href="https://autoadmin.org/terms" class="link">Terms and Conditions</a>.
        </p>
    </div>
    <div class="register-image-box">
        <img src="../assets/main-table-view.svg" class="register-image" alt="">
        <img src="../assets/main-table-view.svg" class="register-image" alt="">
    </div>
</div>

<app-content-loader *ngIf="loading"></app-content-loader>

<app-banner *ngIf="dbFetchError" type="error">
    <p class="mat-body-1 error-message">{{errorMessage}}</p>
    <div class="error-actions">
            <a mat-stroked-button routerLink="/edit-db/{{connectionID}}">Connection settings</a>
            <button mat-flat-button color="warn" (click)="openIntercome()">Chat with support</button>
    </div>
</app-banner>

<app-banner *ngIf="noTablesError" type="info">
    <h3 class='mat-subheading-2'>Autoadmin can not find any tables</h3>
    <p class="mat-body-1">
        Autoadmin don't have access to connection tables or there aren't any.
        Please grant us access to required tables or create new table.
        You can create a table with SQL editor
    </p>
</app-banner>

<ng-container *ngIf="!loading && !dbFetchError && !noTablesError">
    <div class="toggle-button-position"
        [ngClass]="(shownTableTitles)?'toggle-button-position__opened':'toggle-button-position__closed'">
        <button mat-mini-fab class="toggle-button" (click)="sideList.toggle()">
            <mat-icon>{{ shownTableTitles ? 'chevron_left' : 'chevron_right' }} </mat-icon>
        </button>
    </div>
    <mat-sidenav-container>
        <mat-sidenav #sideList mode="side" [(opened)]="shownTableTitles">
            <app-db-tables-list
                [tables]="tablesList"
                [connectionID]="connectionID"
                [selectedTable]="selectedTableName">
            </app-db-tables-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-alert class="server-alert"></app-alert>
            <div class="alerts">
                <app-alert *ngIf="dataSource.alert_settingsInfo" [alert]="dataSource.alert_settingsInfo"></app-alert>
                <app-alert *ngIf="dataSource.alert_primaryKeysInfo" [alert]="dataSource.alert_primaryKeysInfo"></app-alert>
                <app-alert *ngIf="dataSource.alert_widgetsWarning" [alert]="dataSource.alert_widgetsWarning"></app-alert>
            </div>

            <app-content-loader *ngIf="dataSource === null"></app-content-loader>
            <app-db-table *ngIf="dataSource"
                [displayName]="selectedTableDisplayName"
                [activeFilters]="filters"
                [filterComparators]="comparators"
                [name]="selectedTableName"
                [table]="dataSource"
                [rowSelection]="selection"
                [connectionID]="connectionID"
                [accessLevel]="currentConnectionAccessLevel"
                (openFilters)="openTableFilters($event)"
                (removeFilter)="removeFilter($event)"
                (resetAllFilters)="clearAllFilters()"
                (search)="search($event)"
                (deleteRows)="confirmDeleteRows($event)"
                (activateAction)="activateAction($event)"
                (activateActions)="activateActions($event)">
            </app-db-table>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>

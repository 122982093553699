<h1 mat-dialog-title>Confirm action delete</h1>
<div mat-dialog-content>
    <p class="mat-body">
        You are going to delete <strong>{{data.action?.title}}</strong> action.
    </p>
    <br>
    <p class="mat-body">Please confirm.</p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="warn" mat-dialog-close="delete"
        (click)="deleteAction()">
        Delete
    </button>
</div>

<h1 mat-dialog-title>Action</h1>
<div mat-dialog-content>
    <a [href]="data.href" target="_blank" (click)="closeDialog()">
        Take action {{data.actionName}} for
        <strong *ngIf="data.identityFieldValue; else primaryKeysList">{{data.identityFieldValue}}</strong>
        <ng-template #primaryKeysList>
            <strong *ngFor="let primaryKey of data.primaryKeys | keyvalue; let last = last">
                {{primaryKey.key}} = {{primaryKey.value}}<span *ngIf="!last">,</span>
            </strong>
        </ng-template>.
    </a>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</div>

<h1 mat-dialog-title>Confirm {{data.title}} for {{ selectedTableName }} table</h1>
<div mat-dialog-content>
    <p class="mat-body">You are going to {{data.title}} with following primary key(s)
        <strong *ngIf="data.identityFieldValue; else primaryKeysList">{{data.identityFieldValue}}</strong>
        <ng-template #primaryKeysList>
            <strong *ngFor="let primaryKey of data.primaryKeys | keyvalue; let last = last">
                {{primaryKey.key}} = {{primaryKey.value}}<span *ngIf="!last">,</span>
            </strong>.
        </ng-template>
    </p>
    <p class="mat-body">Please confirm.</p>

</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="warn" (click)="handleConfirmedAction()" [disabled]="submitting">{{data.title}}</button>
</div>
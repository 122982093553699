<app-alert></app-alert>
<div class="wrapper">
    <form action="" class="password-form"
        #requestPasswordForm="ngForm"
        (ngSubmit)="requestPassword()">
        <h1 class="mat-h1">Forgot your password?</h1>
        <p class="mat-body-1">
            Enter your Email below and we will send a message to reset your password.
        </p>
        <mat-form-field appearance="outline" class="email-field">
            <mat-label>Email</mat-label>
            <input matInput type="email" name="email" #email="ngModel" required autofocus
                [(ngModel)]="userEmail" >
            <!-- <mat-error *ngIf="hostname.errors?.required && (hostname.invalid && hostname.touched)">Hostname should not be empty.</mat-error> -->
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary"
            [disabled]="submitting || requestPasswordForm.form.invalid || requestPasswordForm.form.pristine">
            {{ submitting ? 'Submitting' : 'Reset my password' }}
        </button>
    </form>
</div>
<app-alert></app-alert>

    <div class="wrapper">
    <div class="login-page">
        <form action="" class="login-form"
            #loginForm="ngForm"
            (ngSubmit)="loginUser()">
            <h1 class="mat-h1">Welcome to Autoadmin</h1>

            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="email" #email="ngModel" required
                    angulartics2On="change"
                    angularticsAction="Login: email is changed"
                    [(ngModel)]="user.email" >
                <!-- <mat-error *ngIf="hostname.errors?.required && (hostname.invalid && hostname.touched)">Hostname should not be empty.</mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" name="password" #password="ngModel" required
                    angulartics2On="change"
                    angularticsAction="Login: password is changed"
                    [(ngModel)]="user.password">
                <mat-hint>
                    <a routerLink="/forget-password" class="link">Forgot password</a>
                </mat-hint>
                <!-- <mat-error *ngIf="port.errors?.required && (port.invalid && port.touched)">Port should not be empty.</mat-error> -->
            </mat-form-field>

            <button type="submit" mat-raised-button color="accent" class="login-button"
                angulartics2On="click"
                angularticsAction="Login: login is clicked"
                [disabled]="submitting || loginForm.form.invalid || loginForm.form.pristine">
                {{ submitting ? 'Submitting' : 'Login'}}
            </button>
            <div class="soc-buttons">
                <div id="google_login_button"
                    angulartics2On="click"
                    angularticsAction="Login: login with facebook is clicked">
                </div>
                <div class="fb-login-button"
                    data-width="200"
                    data-size="large"
                    data-button-type="continue_with"
                    data-layout="default"
                    data-auto-logout-link="false"
                    data-use-continue-as="false"
                    onlogin="loginWithFacebook()"
                    angulartics2On="click"
                    angularticsAction="Login: login with facebook is clicked">
                </div>
            </div>
        </form>
    </div>
</div>


<app-alert></app-alert>
<app-content-loader *ngIf="loading"></app-content-loader>

<app-banner *ngIf="rowError" type="error" class="row-banner">
    <p class="mat-body-1 error-message">{{rowError}}</p>
    <div class="error-actions">
        <a mat-stroked-button routerLink="/dashboard/{{connectionID}}/{{tableName}}">
            Dashboard
        </a>
    </div>
</app-banner>

<div *ngIf="tableRowValues" class="wrapper">
    <div class="row-edit-header">
        <app-breadcrumbs [crumbs]="getCrumbs(currentConnection.title)"></app-breadcrumbs>
        <div *ngIf="rowActions && rowActions.length">
            <button type="button" mat-icon-button *ngFor="let action of rowActions"
                [matTooltip]="action.title"
                (click)="handleActivateAction(action)">
                <mat-icon fontSet="material-icons-outlined">
                    {{action.icon}}
                </mat-icon>
            </button>
        </div>
    </div>
    <form #editRowForm="ngForm" class="form"
        (ngSubmit)="handleRowSubmitting()">
        <div *ngFor="let value of fieldsOrdered; let index = index">
                <!-- <mat-form-field class="example-form-field" appearance="outline">
                    <mat-label>test label</mat-label>
                    <input matInput type="text" required name="test-name" ngModel>
                    <mat-error>!!!</mat-error>
                </mat-form-field> -->
            <div *ngIf="isWidget(value); else defaultTableField" [ngClass]="{ widget: tableWidgets[value].description }">
                <ndc-dynamic [ndcDynamicComponent]="tableWidgets[value].widget_type ? UIwidgets[tableWidgets[value].widget_type] : inputs[tableTypes[value]]"
                    [ndcDynamicInputs]="{
                        key: index,
                        label: tableWidgets[value].name || value,
                        value: tableRowValues[value],
                        required: tableRowRequiredValues[value],
                        readonly: isReadonly(value),
                        widgetStructure: tableWidgets[value],
                        relations: tableTypes[value] === 'foreign key' ? getRelations(value) : undefined
                    }"
                    [ndcDynamicOutputs]="{
                        onFieldChange: { handler: updateField, args: ['$event', value] }
                    }"
                ></ndc-dynamic>
                <mat-icon *ngIf="tableWidgets[value].description"
                    class="widget-info"
                    [ngClass]="{ 'widget-info_centered': tableWidgets[value].widget_type === 'Boolean' || tableTypes[value] === 'boolean' }"
                    [matTooltip]="tableWidgets[value].description"
                    matTooltipPosition="above">
                    info_outline
                </mat-icon>
            </div>

            <ng-template #defaultTableField>
                <ndc-dynamic [ndcDynamicComponent]="inputs[tableTypes[value]]"
                    [ndcDynamicInputs]="{
                        key: index,
                        label: value,
                        value: tableRowValues[value],
                        required: tableRowRequiredValues[value],
                        readonly: isReadonly(value),
                        structure: tableRowStructure[value],
                        relations: tableTypes[value] === 'foreign key' ? getRelations(value) : undefined
                    }"
                    [ndcDynamicOutputs]="{
                        onFieldChange: { handler: updateField, args: ['$event', value] }
                    }"
                ></ndc-dynamic>
            </ng-template>
        </div>

        <div class="actions">
            <button mat-stroked-button type="button" (click)="goBack()">
                Back
            </button>
            <button type="button" mat-button color="primary" *ngIf="keyAttributesFromStructure.length || hasKeyAttributesFromURL"
                class="actions__continue"
                [disabled]="submitting || editRowForm.form.invalid"
                (click)="hasKeyAttributesFromURL ? updateRow(true) : addRow(true)">
                Save and continue editing
            </button>

            <button *ngIf="hasKeyAttributesFromURL; else addButton"
                type="submit" mat-raised-button color="primary"
                [disabled]="submitting || editRowForm.form.invalid">
                Edit
            </button>

            <ng-template #addButton>
                <button type="submit" mat-raised-button color="primary" [disabled]="submitting || editRowForm.form.invalid">
                    Add
                </button>
            </ng-template>
        </div>
    </form>
</div>

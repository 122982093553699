<app-alert></app-alert>
<app-alert *ngIf="currentUser.id && !currentUser.isActive" [alert]="emailVerificationWarning"></app-alert>

<div class="page">
    <h1 class="mat-h1">
        Account settings
    </h1>

    <div class="user-settings mat-body-1">
        <form class="user-settings__section-heading">
            <mat-form-field appearance="outline" class="user-settings__name">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="userName" name="user-name" #nameField="ngModel">
            </mat-form-field>
            <button type="button" mat-button color="primary"
                class="user-settings__save-button"
                (click)="changeUserName()"
                [disabled]="!nameField.dirty || submittingChangedName">
                Save
            </button>
        </form>


        <div class="user-settings__section-heading">
            <strong>Email:</strong>
            <button mat-button color="primary" class="user-settings__button"
                [disabled]="!currentUser.isActive"
                (click)="changeEmail()">
                Change email
            </button>
        </div>
        <span class="user-settings__value">{{currentUser.email}}</span>

        <div class="user-settings__section-heading">
            <strong>Password:</strong>
            <a mat-button routerLink="/change-password" color="primary" class="user-settings__button">
                Change password
            </a>
        </div>
        <span class="user-settings__value">********</span>


        <div class="user-settings__section-heading">
            <strong>Current plan:</strong>
            <a *ngIf="currentUser.portal_link; else updgadePageLink" mat-button color="accent"
                class="upgrade-button"
                [href]="currentUser.portal_link" target="_blank">
                Manage payment settings
            </a>
            <ng-template #updgadePageLink>
                <a mat-button color="accent" class="upgrade-button"
                    routerLink="/upgrade">
                    Manage payment settings
                </a>
            </ng-template>
        </div>
        <span class="user-settings__value">
            <span class="user-settings__value_capitalized">{{currentPlan}}</span>
            <span *ngIf="currentPlan !== 'free'">
                ({{ isAnnually ? 'annually' : 'monthly' }})
            </span>

        </span>

    </div>

    <button mat-button class="delete-button"
        type="button"
        (click)="confirmDeleteAccount()">
        Delete account
    </button>
</div>

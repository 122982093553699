<app-alert></app-alert>
<div class="wrapper">
    <form class="password-form" #changePasswordForm="ngForm" (ngSubmit)="updatePassword()">
        <h1 class="mat-h1">Change password</h1>
        <mat-form-field appearance="outline" class="password-input">
            <mat-label>Enter old password</mat-label>
            <input matInput type="password" [(ngModel)]="oldPassword" name="old-password" #prevPassword="ngModel" required>
            <mat-error *ngIf="prevPassword.errors?.required && (prevPassword.invalid && prevPassword.touched)">Password can not be empty.</mat-error>
        </mat-form-field>

        <app-user-password label="Enter new password" [value]="newPassword" (onFieldChange)="updatePasswordField($event)"></app-user-password>

        <div class="actions">
            <a mat-stroked-button routerLink="/user-settings" class="password-form__back">Back</a>
            <button mat-raised-button color="primary"
                [disabled]="submitting || changePasswordForm.form.invalid">
                    Change
            </button>
        </div>
    </form>
</div>

<div *ngIf="currentAlert" class="alert" [ngClass]="'alert_' + currentAlert.type">
    <mat-icon class="alert__icon">{{icons[currentAlert.type]}}</mat-icon>
    <p *ngIf="currentAlert.message" class="alert__message mat-body-1" [innerHtml]="currentAlert.message"></p>
    <ng-content></ng-content>
    <div *ngIf="currentAlert.actions" class="actions">
        <ng-container *ngFor="let action of currentAlert.actions">
            <button mat-button type="button" *ngIf="action.type === 'button'"
                (click)="onButtonClick(currentAlert, action)">
                {{action.caption}}
            </button>
            <a mat-button *ngIf="action.type === 'external link'"
                [href]="action.to" target="_blank">
                {{action.caption}}
            </a>
            <a mat-button *ngIf="action.type === 'internal link'"
                [routerLink]="action.to">{{action.caption}}</a>
        </ng-container>
    </div>
</div>

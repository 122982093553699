<h1 mat-dialog-title>Are you sure?</h1>
<div mat-dialog-content>
    <p class="mat-body">
        This action is permanent and irreversible, you will not be able to restore your account in the future
    </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close mat-dialog-close [disabled]="submitting">Cancel</button>
  <button mat-raised-button color="warn"
    [disabled]="submitting"
    (click)="deleteAccount()">
        Yes, delete
  </button>
</div>

<app-alert></app-alert>
<div class="wrapper">
    <form #newPasswordForm="ngForm" class="new-password-form" (ngSubmit)="updatePassword()">
        <h1 class="mat-h1">Change your password</h1>
        <app-user-password label="Enter new password" [value]="newPassword" (onFieldChange)="updatePasswordField($event)"></app-user-password>

        <button mat-raised-button color="primary" class="new-password-button"
            [disabled]="submitting || newPasswordForm.form.invalid">
            Change
        </button>
    </form>
</div>

<div *ngIf="isRadiogroup; else checkboxElement" class="radio-line">
    <label id="">{{normalizedLabel}} {{ required ? '*' : '' }}</label>
    <mat-radio-group [required]="required"
        name="{{label}}-{{key}}"
        class="radio-group"
        [disabled]="readonly"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
        <mat-radio-button [value]=true class="radio-button">
            yes
        </mat-radio-button>
        <mat-radio-button [value]=false class="radio-button">
            no
        </mat-radio-button>
        <mat-radio-button [value]=null class="radio-button">
            unknown
        </mat-radio-button>
    </mat-radio-group>
</div>

<ng-template #checkboxElement>
    <mat-checkbox [required]="required"
        name="{{label}}-{{key}}"
        labelPosition="before"
        class="checkbox-line"
        [disabled]="readonly"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
        {{normalizedLabel}} {{ required ? '*' : '' }}
    </mat-checkbox>
</ng-template>

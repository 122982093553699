<app-alert></app-alert>
<app-content-loader *ngIf="!widgets"></app-content-loader>

<div *ngIf="widgets" class="wrapper">
    <header class="header">
        <app-breadcrumbs [crumbs]="getCrumbs(currentConnection.title)" class="row-breadcrumbs"></app-breadcrumbs>

        <div *ngIf="widgets.length" class="header-actions">
            <button  mat-stroked-button
                type="button"
                (click)="openClearAllConfirmation()"
                [disabled]="fields.length === 0 && widgets.length === 0">
                Clear All
            </button>
            <button mat-stroked-button color="accent"
                type="button"
                (click)="addNewWidget()"
                [disabled]="fields.length === 0 || widgets.length === fieldsCount">
                <mat-icon>add</mat-icon>
                Add widget
            </button>
        </div>

    </header>

    <form *ngIf="widgets.length; else emptyState" (ngSubmit)="updateWidgets()" class="widget-settings">
        <ng-container *ngFor="let widget of widgets; let i = index">

            <span *ngIf="widget.field_name; else fieldsSelect" class="widget-field-name">
                {{ widget.field_name }}
            </span>
            <ng-template #fieldsSelect>
                <mat-form-field appearance="outline">
                    <mat-label>Field</mat-label>
                    <mat-select [(ngModel)]="widget.field_name" name="{{i}}-field-name">
                        <mat-option *ngFor="let field of fields"
                            [value]="field" (click)="selectWidgetField(field)">{{ field }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>

            <mat-form-field appearance="outline">
                <mat-label>Widget type</mat-label>
                <mat-select name="{{i}}-field-type"
                    [(ngModel)]="widget.widget_type"
                    (ngModelChange)="onWidgetTypeChange(widget)">
                    <mat-option *ngFor="let widgetType of widgetTypes"
                        [value]="widgetType">{{ widgetType }}</mat-option>
                </mat-select>
            </mat-form-field>

            <ngx-codemirror
                [(ngModel)]="widget.widget_params"
                name="{{i}}-field-params"
                [options]="{
                    lineNumbers: true,
                    lineWrapping: true,
                    mode: 'javascript',
                    theme: 'ttcn',
                    readOnly: isReadOnly(widget.widget_type) ? 'nocursor' : false
                }"
            ></ngx-codemirror>

            <mat-form-field appearance="outline">
                <mat-label>Field name</mat-label>
                <input matInput [(ngModel)]="widget.name" name="{{i}}-field-new-name">
                <!-- <mat-error *ngIf="username.errors?.required && (username.invalid && username.touched)">Username should not be empty.</mat-error> -->
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput [(ngModel)]="widget.description" name="{{i}}-field-description"></textarea>
                <!-- <mat-error *ngIf="sslCert.errors?.required && (sslCert.invalid && sslCert.touched)">SSL certificate should not be empty.</mat-error> -->
            </mat-form-field>

            <button mat-icon-button type="button" class="widget-delete-button"
                matTooltip="Delete widget"
                (click)="openDeleteWidgetDialog(widget.field_name)">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </ng-container>


        <div class="actions">
            <a mat-stroked-button routerLink="/dashboard/{{connectionID}}/{{tableName}}">
                Back
            </a>

            <button mat-raised-button color="primary"
                type="submit" [disabled]="submitting">
                Save
            </button>
        </div>
    </form>

    <ng-template #emptyState>
        <div class="empty-state">
            <p class="empty-state__text">No widgets added</p>
            <button mat-flat-button
                color="accent"
                (click)="addNewWidget()">
                <mat-icon>add</mat-icon>
                Add widget
            </button>

            <div class="actions">
                <button mat-stroked-button type="button" (click)="goBack()">
                    Back
                </button>

                <button mat-raised-button color="primary"
                    type="submit" disabled>
                    Save
                </button>
            </div>
        </div>
    </ng-template>
</div>
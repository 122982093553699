<app-alert></app-alert>

<div class="wrapper">
    <form #passwordForm="ngForm" class="password-form" (ngSubmit)="verifyGroupUser()">
        <h1 class="mat-h1">Enter password to create account on Autoadmin</h1>
        <app-user-password label="Enter password" [value]="password" (onFieldChange)="updatePasswordField($event)"></app-user-password>

        <button mat-raised-button color="primary" class="new-password-button"
            [disabled]="submitting || passwordForm.form.invalid">
            Create
        </button>
    </form>
</div>